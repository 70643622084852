import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { Config, RangeConfig } from 'src/app/views/scenario/model/scenario.model';
import { Seasonality } from 'src/app/views/scenario/reports/model/seasonality.model';
import { INCLUDE_MOMENT_START_DATE } from '../app.constants';
import { IPandasDateRange } from '../config/models/config';

const FORMAT_DATE = 'YYYY-MM-DD';

export class CustomValidators {

  static percentage(formControl: AbstractControl) {
    if (formControl.value < 0 || formControl.value > 100) {
      return {
        percentage: true
      };
    }
    return null;
  }

  static notZero(formControl: AbstractControl) {
    if (formControl.value === 0) {
      return {
        notZero: true
      };
    }
    return null;
  }

  static requiredIfCustom(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('numberOfDays').value === 0) {
      return Validators.required(formControl);
    } else {

    }
    return null;
  }

  static dateMin(date: moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = control.value;

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrAfter(validationDate) ? null : {
        'date-min': {
          'date-min': validationDate.format(FORMAT_DATE),
          actual: controlDate.format(FORMAT_DATE)
        }
      };
    };
  }

  static dateMax(date: moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = control.value;

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrBefore(validationDate) ? null : {
        'date-max': {
          'date-max': validationDate.format(FORMAT_DATE),
          actual: controlDate.format(FORMAT_DATE)
        }
      };
    };
  }

  static date(control: AbstractControl): ValidationErrors | null {

    const error = {
      date: 'Please enter a valid date'
    };

    if (control instanceof UntypedFormGroup) {
      const date = control.get('date');

      if (date.value === null) {
        date.setErrors(error);
        return error;
      }

      if (!date.value.isValid()) {
        date.setErrors(error);
        return error;
      }
      return null;
    }

    if (control.value == null) {
      return error;
    }

    if (!control.value.isValid()) {
      return error;
    }

    return null;
  }

  static validateRangeDates(config: Config): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      const errors = {
        inputRange: {
          endBeforeStart: {
            errorKey: 'endBeforeStart'
          },
          outOfRange: {
            errorKey: 'invalidInputRange'
          }
        },
        newUsers: {
          seasonality: {
            errorKey: 'newUsersSeasonalityError'
          },
        },
        retention: {
          seasonality: {
            errorKey: 'retentionSeasonalityError'
          },
          extrapolation: {
            errorKey: 'retentionExtrapolationError'
          },
        },
        userRevenue: {
          seasonality: {
            errorKey: 'userRevenueSeasonalityError'
          },
          extrapolation: {
            errorKey: 'userRevenueExtrapolationError'
          },
        }
      };

      if (control instanceof UntypedFormControl) {

        if (!config) return Validators.required(control);
        if (!control?.parent?.parent) return Validators.required(control);
        if (control?.parent?.get('relative').value) return null;

        const {newUsers, retention, userRevenue, inputRange: {inputDataEnd, inputDataStart}} = config;

        const inputRangeControl = control.parent.parent;
        const endDate = inputRangeControl.get('range').get('date');
        const startDate = inputRangeControl.get('startRange').get('date');
        const endRelative = inputRangeControl.get('range').get('relative');
        const startRelative = inputRangeControl.get('startRange').get('relative');

        const maxDate = endRelative.value ? inputDataEnd : endDate.value;
        const minDate = startRelative.value ? inputDataStart : startDate.value;

        switch (true) {
          case isLessThanSeasonalityDate(newUsers.seasonality, maxDate, control.value):
            endDate.setErrors(errors.newUsers.seasonality);
            return errors.newUsers.seasonality;

          case isLessThanExtrapolationDate(retention.extrapolation, maxDate, control.value):
            endDate.setErrors(errors.retention.extrapolation);
            return errors.retention.extrapolation;

          case isLessThanSeasonalityDate(retention.seasonality, maxDate, control.value):
            endDate.setErrors(errors.retention.seasonality);
            return errors.retention.seasonality;

          case isLessThanExtrapolationDate(userRevenue.extrapolation, maxDate, control.value):
            endDate.setErrors(errors.userRevenue.extrapolation);
            return errors.userRevenue.extrapolation;

          case isLessThanSeasonalityDate(userRevenue.seasonality, maxDate, control.value):
            endDate.setErrors(errors.userRevenue.seasonality);
            return errors.userRevenue.seasonality;

          case moment(control.value).isBefore(moment(inputDataStart)):
          case moment(control.value).isAfter(moment(inputDataEnd)):
            return errors.inputRange.outOfRange;

          case moment(minDate).isSameOrAfter(moment(maxDate)):
            return errors.inputRange.endBeforeStart;

          default:
            if (endDate.invalid) {
              endDate.updateValueAndValidity();
            }
            if (startDate.invalid) {
              startDate.updateValueAndValidity();
            }
            return null;
        }
      }

      if (control.value == null) {
        return errors.inputRange.outOfRange;
      }

      if (!control.value.isValid()) {
        return errors.inputRange.outOfRange;
      }

      return null;
    };
  }

  static validateExtrapolationDates(inputData: IPandasDateRange): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      const error = {
        error: 'Select a date within the input range'
      };

      if (control instanceof UntypedFormControl) {
        if (!inputData) return Validators.required(control);

        if (moment(inputData.min).isAfter(moment(control.value))) {
          control.markAsTouched();
          return error;
        }

        if (moment(inputData.max).add(INCLUDE_MOMENT_START_DATE, 'day').isSameOrBefore(moment(control.value))) {
          control.markAsTouched();
          return error;
        }

        return null;
      }

      if (control.value == null) {
        return error;
      }

      if (!control.value.isValid()) {
        return error;
      }

      return null;
    };
  }

  static validateDxAdjustment(config: Config): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      if (!config) return Validators.required(control);

      const getLookbackDays = (endDate: string | Date): number => {
        const {relative, numberOfDays, date} = config.retention.extrapolation;
        return (relative) ? +numberOfDays : moment(endDate).diff(moment(date), 'days') + INCLUDE_MOMENT_START_DATE;
      }

      const getForecastDays = (endDate: string | Date): number => {
        const {relative, numberOfDays, date} = config.output.range;
        return (relative) ? +numberOfDays : moment(date).diff(moment(endDate), 'days') + INCLUDE_MOMENT_START_DATE;
      }

      const {apply} = config.retention.dxAdjustment;
      const {end} = config.inputRange;

      if (apply) {

        const lookback = getLookbackDays(end);
        const forecast = getForecastDays(end);

        const error = {
          error: `Dx adjustment must be greater than ${lookback} or less than ${
            lookback + forecast
          }`,
        };

        if (lookback >= control.value || control.value >= lookback + forecast) {
          control.setErrors(error);
          return error;
        }

        if (control.value == null) {
          return error;
        }

        if (!control.valid) {
          return error;
        }
      }

      return null;
    };
  }

  static uniqueNameValidator<T extends { name?: string }>(list: T[]): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const name = control.value.replace(/\s+/g,' ').trim();
      const isInvalid = Boolean(list.find((item) => item.name.trim() === name));
      return !isInvalid ? null : {'unique': true};
    }
  }

  static hasNoWhitespace(control: AbstractControl<string>): ValidationErrors | null {
    const isInvalid = control.value.trim()?.length < 1;
    return !isInvalid ? null : {'required': true};
  }

}

function isLessThanExtrapolationDate(extrapolation: RangeConfig, endDate: string, currentControl: string) {
  if (!Boolean(extrapolation)) return;
  const {date, relative} = extrapolation;
  const isLessThanExtrapolationDate = moment(endDate).isSameOrBefore(moment(date)) && !relative;
  const isActiveControl = moment(endDate).isSame(moment(currentControl))
  return isLessThanExtrapolationDate && isActiveControl;
}

function isLessThanSeasonalityDate(seasonality: Seasonality, endDate: string, currentControl: string) {
  const {extrapolation, fit: {calendarDate}} = seasonality;
  const isLessThanSeasonalityDate = isLessThanExtrapolationDate(extrapolation, endDate, currentControl);
  return isLessThanSeasonalityDate && calendarDate;
}
