import { Inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as FullStory from '@fullstory/browser';
import { AuthService, IdToken, User } from '@auth0/auth0-angular';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppConfig, Auth0UserTokenKeys } from '../config/models/config';
import { SentryService } from '../sentry/sentry';
import { AnalyticsNextService } from '../analytics-next/analytics-next';
import { HelpersService } from './helpers.service';
import { EventService } from './event.service';
import { Intercom } from '../intercom/intercom';
import { APP_CONFIG, INTERNAL_USER_LOCAL_STORAGE_KEY } from "../app.constants";
import { environment } from 'src/environments/environment';
import { toBool } from '../util/string.utils';
import { BeamsService } from "./beams.service";

@Injectable({providedIn: 'root'})
export class AccountService {

  constructor(
    auth0Service: AuthService,
    private helpersService: HelpersService,
    private sentry: SentryService,
    private eventService: EventService,
    private beamsService: BeamsService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private intercom: Intercom,
    private analytics: AnalyticsNextService) {
    auth0Service.idTokenClaims$.pipe(
      filter(token => Boolean(token)),
      distinctUntilChanged((a, b) => a.org_id === b.org_id)
    ).subscribe((token: IdToken) => {
      this.setToken(token);
      this.intercomInit(token);
      this.fullStoryInit(token);
    });
  }

  private authenticated = signal<boolean>(false);
  private authenticationState = new BehaviorSubject<User>(null);
  private onTokenChangedSubject$ = new BehaviorSubject<IdToken>(null);
  private authToken = signal<IdToken>(null);
  public authRoles = signal<string[]>(null);
  public onTokenChanged$ = this.onTokenChangedSubject$.asObservable();

  private static identifyFullStory(identity: User) {
    try {
      FullStory.identify(identity.sub, {
        displayName: identity.name,
        email: identity.email
      });
    } catch (e) {
      console.warn('FullStory not initialized');
    }
  }

  intercomInit(token: IdToken) {
    const {analyticsNext, embedDomain, auth0: {rampOrgId}} = this.appConfig;
    const {org_id, email} = token;
    const isKohortOrg = rampOrgId === org_id;
    const isKohortUser = this.isKohortUser(email, embedDomain);
    const showIntercom = (isKohortOrg && isKohortUser) || !isKohortUser;

    if (analyticsNext.enabled) {
      this.analytics.init(analyticsNext).then(() => {
        if (showIntercom) {
          this.intercom.boot();
        } else {
          this.intercom.shutdown();
        }
      });
    } else {
      console.warn('Segment analytics-next not initialized');
    }
  }

  fullStoryInit(token: IdToken) {
    const {fullStory, embedDomain} = this.appConfig;
    const isKohortUser = this.isKohortUser(token.email, embedDomain);
    if (fullStory.enabled && !isKohortUser) {
      FullStory.init({
        orgId: fullStory.orgId,
        devMode: !toBool(environment.production),
        recordCrossDomainIFrames: true,
        recordOnlyThisIFrame: false
      });
    }
  }

  isKohortUser(email: string, domain: string): boolean {
    const emailDomain = this.helpersService.getDomainFromEmail(email);
    return domain.includes(emailDomain);
  }

  authenticate(identity: User) {
    const {sub, name, email, org_id, org_name, created_at} = identity;
    this.analytics.setUser(sub, {
      id: sub,
      name,
      email,
      createdAt: created_at,
      company: {
        id: org_id,
        name: org_name
      }
    });
    this.sentry.setUser(identity);
    AccountService.identifyFullStory(identity);
    this.eventService.start(org_id, identity);
    this.beamsService.start(sub);
    this.authenticated.set(identity !== null);
    this.authenticationState.next(identity);
    // clear the internal user flag when switching orgs
    this.helpersService.deleteFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY);
  }

  hasAnyAuthority(authorities: string[], isAuthenticated = null): Observable<boolean> {
    if (!authorities) {
      return of(true);
    }

    if (isAuthenticated !== null && typeof isAuthenticated === 'boolean') {
      this.authenticated.set(isAuthenticated);
    }

    if (!this.authenticated()) {
      return of(false);
    }

    return this.onTokenChanged$.pipe(
      filter(token => Boolean(token)),
      map(token => this.getTokenRoles(token)),
      map(roles => roles.map((role: string) => `ROLE_${role.replace(' ', '_')}`).some((role: string) => authorities.includes(role)))
    );
  }

  hasAnyAuthRole(roles: string[]): boolean {
    return this.authRoles().map((role: string) => `ROLE_${role.replace(' ', '_')}`).some((role: string) => roles.includes(role));
  }

  hasAuthRoles(roles: string[]): boolean {
    const authRoles = this.authRoles().map((role: string) => `ROLE_${role.replace(' ', '_')}`);
    return roles.every((role: string) => authRoles.includes(role));
  }

  getAuthenticationState(): Observable<User> {
    return this.authenticationState.asObservable();
  }

  setToken(authToken: IdToken) {
    const internalUser = this.helpersService.getFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY).isInternal;
    const tokenRoles = this.getTokenRoles(authToken);
    const roles = internalUser ? [...tokenRoles, 'INTERNAL'] : tokenRoles;
    const token = {
      ...authToken,
      ...(roles.length && {[Auth0UserTokenKeys.ROLES]: roles})
    };
    if (this.authToken()?.__raw !== authToken?.__raw) {
      this.onTokenChangedSubject$.next(token);
    }
    this.authToken.set(token);
  }

  getTokenRoles(token: IdToken): string[] {
    return token[Auth0UserTokenKeys.ROLES] || [];
  }

  setInternalRole(isInternal: boolean) {
    const roles = this.getTokenRoles(this.authToken());
    const includeInternal = !roles.includes('INTERNAL') ? [...roles, 'INTERNAL'] : roles;
    const updatedRoles: string[] = this.initInternalToggleValue(isInternal) ? includeInternal : roles.filter(role => role !== 'INTERNAL');

    const authToken = {
      ...this.authToken(),
      [Auth0UserTokenKeys.ROLES]: updatedRoles
    };
    this.authToken.set(authToken);
    this.onTokenChangedSubject$.next(authToken);
    this.authRoles.set(updatedRoles);
  }

  initInternalToggleValue(isInternal: boolean): boolean {
    const internalUser = this.helpersService.getFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY).isInternal;
    if(typeof internalUser === 'boolean') {
      return internalUser;
    } else {
      this.helpersService.saveToLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY, {isInternal});
      return isInternal;
    }
  }

  resetEventTrackers() {
    this.analytics.shutdown();
  }
}
