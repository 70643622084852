import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CustomMaterialModule } from './material.module';
import { DurationPipe } from './pipes/duration.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CloseButtonComponent } from './ui/close-button/close-button.component';
import { CancelButtonComponent } from './ui/cancel-button/cancel-button.component';
import { DragDropDirective } from './directives/dragdrop.directive';
import { MatSpinnerButtonComponent } from './ui/spinner-button/spinner-button.component';
import { ConfirmationDialogComponent } from './ui/confirmation-dialog/confirmation-dialog.component';
import { NotificationComponent } from './shared/notification/notification.component';
import { HasAuthorityDirective } from './shared/has-authority.directive';
import { SafePipe } from './pipes/safe.pipe';
import { LookerIframeComponent } from './ui/looker-iframe/looker-iframe.component';
import { LookerGranularityComponent } from './looker/looker-granularity/looker-granularity.component';
import { FocusableDirective } from './directives/focusable.directive';
import { BooleanPipe } from './pipes/boolean.pipe';
import { IntercomModule } from './intercom/intercom.module';
import { TimePipe } from './pipes/time.pipe';
import { DurationCounterComponent } from './ui/duration-counter/duration-counter.component';
import { RouterModule } from '@angular/router';
import { ScenarioProgressComponent } from '../views/scenario/partials/scenario-progress/scenario-progress.component';
import { HumanizePipe } from './pipes/humanize.pipe';
import { getIntercomConfig, INTERCOM_CONFIG } from './intercom/intercom.config';
import { FooterComponent } from './shared/footer/footer.component';
import { DatePickerComponent } from './ui/date-picker/date-picker.component';
import { NavigationLoaderComponent } from './ui/navigation-loader/navigation-loader.component';
import { StickyTopDirective } from './directives/sticky-top.directive';
import { DayTimeSelectComponent } from './ui/day-time-select/day-time-select.component';
import { WeekdaysTranslatePipe } from './pipes/weekdays-translate.pipe';
import { PrettifyPipe } from './pipes/prettify.pipe';
import { GraphQLModule } from '../graphql/graphql.module';
import { SegmentInputComponent } from '../views/events/components/controls/segment-input/segment-input.component';
import { ImageIconComponent } from './ui/image-icon/image-icon.component';
import { UpdateAppDialogueComponent } from './ui/update-app-dialogue/update-app-dialogue.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { BannerComponent } from './ui/banner/banner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ErrorTranslatePipe } from './pipes/error-translate.pipe';
import { RequiredControlDirective } from './directives/required-control.directive';
import { NavListItemComponent } from './ui/side-nav/nav-list-item/nav-list-item.component';
import { NavListGroupComponent } from './ui/side-nav/nav-list-group/nav-list-group.component';
import { NavListGroupItemComponent } from './ui/side-nav/nav-list-group-item/nav-list-group-item.component';
import { NavListFavItemComponent } from './ui/side-nav/nav-list-fav-item/nav-list-fav-item.component';
import { UserMenuComponent } from './ui/user-orgs-menu/user-menu/user-menu.component';
import { OrganisationMenuComponent } from './ui/user-orgs-menu/organisation-menu/organisation-menu.component';
import { UserOrgsMenuComponent } from './ui/user-orgs-menu/user-orgs-menu.component';
import { SvgImagesComponent } from './ui/svg-images/svg-images.component';
import { SelectCardComponent } from './ui/select-card/select-card.component';
import { DropdownMenuComponent } from './ui/dropdown-menu/dropdown-menu.component';
import { ButtonComponent } from './ui/button/button.component';
import { SegmentSummaryComponent } from './ui/segment-summary/segment-summary.component';
import { ProductHeaderComponent } from './ui/product-header/product-header.component';
import { SearchSortComponent } from './ui/search-sort/search-sort.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { PanelListGroupComponent } from './ui/panel-list/panel-list-group/panel-list-group.component';
import { PanelListItemComponent } from './ui/panel-list/panel-list-item/panel-list-item.component';
import { BreadcrumbsMenuComponent } from './breadcrumbs-menu/breadcrumbs-menu.component';
import { BreadcrumbsDropdownComponent } from './breadcrumbs-menu/breadcrumbs-dropdown/breadcrumbs-dropdown.component';
import { DropdownScrollDirective } from './directives/dropdown-scroll.directive';
import { BreadcrumbsDropdownItemComponent } from './breadcrumbs-menu/breadcrumbs-dropdown-item/breadcrumbs-dropdown-item.component';
import { ActivityIconComponent } from './activity/activity-icon/activity-icon.component';
import { BadgeComponent } from './ui/badge/badge.component';
import { StateComponent } from './ui/state/state.component';
import { SwitchToggleComponent } from './ui/switch-toggle/switch-toggle.component';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { ScenarioProgressV2Component } from '../views/scenario/partials/scenario-progress-v2/scenario-progress-v2.component';
import { DropdownMenuItemComponent } from './ui/dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import { DateTimeAgoPipe } from './pipes/date-time-ago.pipe';
import { PopperDirective } from './directives/popper.directive';
import { MainHeaderComponent } from './ui/main-header/main-header.component';
import { DialogModule } from '@angular/cdk/dialog';
import { ThemeSwitcherComponent } from './ui/theme-switcher/theme-switcher.component';
import { SegmentLabelComponent } from './ui/segment-label/segment-label.component';
import { SegmentPickerComponent } from './ui/segment-picker/segment-picker.component';
import { APP_CONFIG } from "./app.constants";
import { ObserveElementDirective } from './directives/observe-element.directive';
import { ScenarioPageHeaderComponent } from '../views/scenario/scenario-page-header/scenario-page-header.component';
import { DialogComponent } from './ui/dialog/dialog.component';
import { OverlayPopupComponent } from './ui/overlay-popup/overlay-popup.component';
import { LoadingComponent } from './layout/loading.component';
import { NoScrollDirective } from './directives/no-scroll.directive';
import { PillToggleComponent, PillToggleItemComponent } from './ui/pill-toggle/pill-toggle.component';
import { SelectComponent } from './ui/select/select.component';
import { OptionComponent } from './ui/select/option/option.component';
import { ResizeDirective } from './directives/resize/resize.directive';
import { TooltipDirective } from './directives/tooltip.directive';

@NgModule({
  declarations: [
    DurationPipe,
    CancelButtonComponent,
    DragDropDirective,
    MatSpinnerButtonComponent,
    ConfirmationDialogComponent,
    CapitalizePipe,
    NotificationComponent,
    SafePipe,
    LookerIframeComponent,
    LookerGranularityComponent,
    FocusableDirective,
    DisableControlDirective,
    RequiredControlDirective,
    ObserveElementDirective,
    BooleanPipe,
    TimePipe,
    DurationCounterComponent,
    ScenarioProgressComponent,
    HumanizePipe,
    BreadcrumbsMenuComponent,
    FooterComponent,
    DatePickerComponent,
    NavigationLoaderComponent,
    StickyTopDirective,
    ResizeDirective,
    DayTimeSelectComponent,
    WeekdaysTranslatePipe,
    PrettifyPipe,
    UpdateAppDialogueComponent,
    SegmentInputComponent,
    BannerComponent,
    ErrorTranslatePipe,
    NavListFavItemComponent,
    UserMenuComponent,
    OrganisationMenuComponent,
    UserOrgsMenuComponent,
    SelectCardComponent,
    ProductHeaderComponent,
    BreadcrumbsDropdownComponent,
    BreadcrumbsDropdownItemComponent,
    PanelListGroupComponent,
    ActivityIconComponent,
    PanelListItemComponent,
    ScenarioProgressV2Component,
    DateTimeAgoPipe,
    MainHeaderComponent,
    ThemeSwitcherComponent,
    ScenarioPageHeaderComponent,
    SelectComponent,
    OptionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HasAuthorityDirective,
    OutsideClickDirective,
    DropdownScrollDirective,
    TooltipDirective,
    ReactiveFormsModule,
    CustomMaterialModule,
    TranslateModule,
    IntercomModule,
    GraphQLModule,
    CdkMenuModule,
    DialogModule,
    ImageIconComponent,
    OverlayModule,
    ImageIconComponent,
    SvgImagesComponent,
    SearchSortComponent,
    DropdownMenuComponent,
    SegmentSummaryComponent,
    SegmentLabelComponent,
    SegmentPickerComponent,
    ButtonComponent,
    BadgeComponent,
    StateComponent,
    SwitchToggleComponent,
    PillToggleComponent,
    PillToggleItemComponent,
    DropdownMenuItemComponent,
    PaginationComponent,
    PopperDirective,
    NoScrollDirective,
    DialogComponent,
    OverlayPopupComponent,
    CloseButtonComponent,
    NavListItemComponent,
    NavListGroupItemComponent,
    NavListGroupComponent,
    LoadingComponent
  ],
  exports: [
    ReactiveFormsModule,
    CustomMaterialModule,
    CdkMenuModule,
    DialogModule,
    OverlayModule,
    DurationPipe,
    StateComponent,
    CommonModule,
    DragDropDirective,
    CancelButtonComponent,
    MatSpinnerButtonComponent,
    CapitalizePipe,
    NotificationComponent,
    HasAuthorityDirective,
    SafePipe,
    LookerIframeComponent,
    LookerGranularityComponent,
    FocusableDirective,
    TooltipDirective,
    DisableControlDirective,
    RequiredControlDirective,
    ObserveElementDirective,
    BooleanPipe,
    IntercomModule,
    TimePipe,
    DurationCounterComponent,
    HumanizePipe,
    MainHeaderComponent,
    BreadcrumbsMenuComponent,
    FooterComponent,
    TranslateModule,
    DatePickerComponent,
    NavigationLoaderComponent,
    ScenarioProgressComponent,
    StickyTopDirective,
    ResizeDirective,
    DayTimeSelectComponent,
    WeekdaysTranslatePipe,
    PrettifyPipe,
    SegmentInputComponent,
    BannerComponent,
    ErrorTranslatePipe,
    NavListItemComponent,
    NavListGroupComponent,
    NavListGroupItemComponent,
    NavListFavItemComponent,
    UserMenuComponent,
    OrganisationMenuComponent,
    UserOrgsMenuComponent,
    SvgImagesComponent,
    SelectCardComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    ButtonComponent,
    SegmentSummaryComponent,
    SegmentLabelComponent,
    SegmentPickerComponent,
    ProductHeaderComponent,
    SearchSortComponent,
    OutsideClickDirective,
    DropdownScrollDirective,
    BreadcrumbsDropdownItemComponent,
    PanelListGroupComponent,
    PanelListItemComponent,
    BadgeComponent,
    SwitchToggleComponent,
    PillToggleComponent,
    PillToggleItemComponent,
    ScenarioProgressV2Component,
    DateTimeAgoPipe,
    PopperDirective,
    NoScrollDirective,
    PaginationComponent,
    OverlayPopupComponent,
    ThemeSwitcherComponent,
    ScenarioPageHeaderComponent,
    DialogComponent,
    CloseButtonComponent,
    LoadingComponent,
    SelectComponent,
    OptionComponent,
    ActivityIconComponent,
    BreadcrumbsDropdownComponent
  ],
  providers: [
    DatePipe,
    CapitalizePipe,
    HumanizePipe,
    {
      provide: INTERCOM_CONFIG,
      useFactory: getIntercomConfig,
      deps: [APP_CONFIG],
    },
  ],
})
export class SharedModule {}
