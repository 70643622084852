import { createAction, props } from '@ngrx/store';

export const setPageHeaderContent = createAction(
  '[Page Heading] Set Page Heading',
  props<{ pageTitle?: string; }>()
);

export const saveToLocalStorage = createAction(
  '[Page] Save to local storage',
  props<{ key: string; value: { [key: string]: any;  }; }>()
);

export const pageApiError = createAction(
  '[Page] API Error',
  props<{ error: any; }>()
);

