import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  inject, EventEmitter, Output, OnInit, OnDestroy, DestroyRef
} from '@angular/core';
import { LookerResponse } from '../../services/looker.service';
import { Preset } from '../../looker/looker-granularity/looker-granularity.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getProductSegments, trackItem } from 'src/app/store';
import { RESET_FILTERS, REPORTS_BY_SEGMENT_ROUTES } from '../../app.constants';
import { ReportingActions } from "../../../store/actions/reporting.actions";
import { Helpers } from "../../shared/helpers";
import { selectDefaultFilters } from "../../../store/selectors/reporting.selectors";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { take } from "rxjs/operators";
import { Theme } from "../../services/theme.service";

@Component({
  selector: 'app-looker-iframe',
  templateUrl: './looker-iframe.component.html',
  styleUrls: ['./looker-iframe.component.scss'],
})
export class LookerIframeComponent implements OnChanges, OnInit, OnDestroy {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private destroyRef: DestroyRef) {
    this.isBySegmentsChart = (
      REPORTS_BY_SEGMENT_ROUTES.includes(this.activatedRoute.snapshot?.routeConfig?.path?.toLocaleLowerCase())
      &&
      this.router.url.includes('scenarios')
    );
  }

  myListener = this.lookerListener.bind(this);

  ngOnInit(): void {
    window.addEventListener('message', this.myListener);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.myListener);
  }

  lookerListener(event) {
    if (event.source === this.lookerIframe.nativeElement.contentWindow) {
      const data = JSON.parse(event.data);
      this.lookerLoaded.next(true);

      if (data.type === 'page:properties:changed') {
        this.dashboardHeight.emit(data.height);
      }
      if (data.type === 'dashboard:loaded') {
        this.onLoaded.emit(data);
        this.elements = data.dashboard.options.elements;
        this.dashboardLoaded = true;
        const { dashboard_filters } = data.dashboard;
        this.store.dispatch(ReportingActions.setDefaultFilters({ filters: dashboard_filters, report: this.dashboard }));
        this.hasScenarioFilter.emit(dashboard_filters.hasOwnProperty('Scenario'));
        this.loading = false;
      }
      if (data.type === 'dashboard:filters:changed') {
        const { dashboard_filters } = data.dashboard;
        const isFiltersReset = RESET_FILTERS.some(key => dashboard_filters[key] === '');
        if (!isFiltersReset) {
          this._syncFiltersToUrl(dashboard_filters);
        } else {
          this.loading = true;
          this.restoreDefaultFilters()
        }
      }
      if (data.type === 'dashboard:run:complete') {
        if (this.loading) {
          this.loading = false;
        }
      }

      if (['dashboard:download', 'dashboard:tile:download', 'dashboard:tile:explore'].includes(data.type)) {
        const { product, scenario } = this.activatedRoute.snapshot.data;
        const { dashboard, tile, type } = data;
        const eventName = data.type.includes('download') ? 'Download' : 'Explore';
        this.store.dispatch(trackItem({
          label: 'Looker Dashboard ' + eventName,
          data: {
            product_id: product?.id,
            product_name: product?.name,
            ...(!!scenario?.id ? { scenario_id: scenario.id } : null),
            ...(!!scenario?.name ? { scenario_name: scenario.name } : null),
            title: (tile?.title || dashboard?.title),
            type
          }
        }));
      }
    }
  }

  get isLoading() {
    return this.loading;
  }

  get instanceName() {
    const url = new URL(this.currentUrl);
    return `${ url.protocol }//${ url.host }`;
  }

  @Input() url: LookerResponse;
  @Input() loaded: boolean;
  @Input() visible = true;
  @Input() dashboard!: string;
  @Input() theme: Theme;
  @Output() onLoaded = new EventEmitter();
  @Output() dashboardHeight = new EventEmitter();
  @Output() hasScenarioFilter = new EventEmitter<boolean>();
  @ViewChild('lookerIframe', { static: true }) lookerIframe: ElementRef;
  private store = inject(Store);
  private loading = false;
  public segments$ = this.store.select(getProductSegments);
  public isBySegmentsChart = false;
  public dashboardLoaded = false;
  public currentUrl = 'about:blank';
  public elements;
  public lookerLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url?.currentValue === null) {
      this.loading = true;
      return;
    }
    // If the theme changes we need to reload the URL to update it.
    if (changes.theme && this.lookerLoaded.value) {
      this.lookerLoaded.next(false);
    }
    // Looker is loaded - we can now load the dashboard without a iframe src update
    if (this.lookerLoaded.value && changes.url && !!changes.url.currentValue) {
      this.currentUrl = this.url?.url;
      this.loadDashboard(changes.url.currentValue?.url)
      this.loading = false;
      return;
    }
    // This fixes the flicker when switching between products/scenarios via breadcrumbs
    if (changes.url) {
      this.loading = true;
    }
    if (changes.loaded && (changes.loaded as SimpleChange).currentValue === true) {
      this.currentUrl = this.url?.url;
      this.lookerIframe.nativeElement.src = this.currentUrl;
    } else {
      if (this.loaded && changes.url) {
        this.currentUrl = changes.url.currentValue?.url;
        this.lookerIframe.nativeElement.src = this.currentUrl;
      }
    }
    if (!this.lookerLoaded.value) {
      this.loading = true;
    }
  }

  public refresh() {
    if (this.currentUrl !== 'about:blank' && this.lookerIframe.nativeElement.contentWindow != null) {
      const reloadRequest = JSON.stringify(
        {
          type: 'dashboard:run'
        }
      );
      this.lookerIframe.nativeElement.contentWindow.postMessage(reloadRequest, this.instanceName);
    }
  }

  public update(filters?) {
    if (this.currentUrl !== 'about:blank' && this.lookerIframe.nativeElement.contentWindow != null) {
      const reloadRequest = JSON.stringify(
        {
          type: 'dashboard:filters:update',
          filters,
        }
      );
      this.lookerIframe.nativeElement.contentWindow.postMessage(reloadRequest, this.instanceName);
      this.refresh();
    }
  }

  public loadDashboard(url: string) {
    /**
     * There was no easy way to get the dashboard id from the url so we have to manipulate it
     * We would need to make the frontend looker model aware which just seems unnecessary.
     * We need to store the relative model on the frontend eg. stage::new_users_segments
     */
    const id = decodeURIComponent(url.split("/login/embed/")[1]).split("?nonce=")[0].split("/embed/dashboards/")[1]
    const reloadRequest = JSON.stringify(
      {
        type: 'dashboard:load',
        id
      }
    );
    this.lookerIframe.nativeElement.contentWindow.postMessage(reloadRequest, this.instanceName);
  }

  toggleFilters($event: Preset) {
    const filters = { ...$event.filters };
    this.update(filters);
  }

  private _syncFiltersToUrl(filters, sync = true) {
    const params = this.router.routerState.snapshot.root.queryParams;
    const newParams = Helpers.removeEmpty({ ...params, ...filters });
    if (sync) {
      this.store.dispatch(ReportingActions.updateFilters({ filters: newParams }));
    }
    this.router.navigate([], { queryParams: newParams });
  }

  /**
   * Restore default filters
   * @private
   */
  private restoreDefaultFilters() {
    this.store.select(selectDefaultFilters(this.dashboard)).pipe(
      take(1),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((filters) => {
      this.update(filters);
    })
  }
}

