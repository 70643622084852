import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { USER_MANAGEMENT_ROUTE } from 'src/app/core/app.constants';
import { OnDestroy } from 'src/app/core/shared/rxjs-helpers';
import { IOrganisation, IUser } from 'src/app/views/user-management/model/user-management.model';
import pkg from "../../../../../../package.json";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styles: [`
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
    }
  `]
})
export class UserMenuComponent extends OnDestroy implements OnInit {
  @Input({required: true}) currentOrganisation: IOrganisation;
  @Input({required: true}) toggleInternal: boolean;
  @Input({required: true}) user: IUser;
  @Input() isNewUI: boolean;
  @Output() logout: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleInternalMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleUI: EventEmitter<boolean> = new EventEmitter<boolean>();

  public userManagementRoute = USER_MANAGEMENT_ROUTE;
  public toggleInternalControl: FormControl<boolean>;
  public version = pkg.version;

  ngOnInit(): void {
    this.toggleInternalControl = new FormControl(this.toggleInternal);
    this.toggleInternalControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((value) => {
      this.toggleInternalMode.emit(value);
    });
  }
}
