import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userManagementFeatureKey } from '../reducers/user-management.reducer';
import { UserManagementState } from '../reducers';
import { IOrganisation } from '../../model/user-management.model';

export const getAllUserManagement = createFeatureSelector<UserManagementState>(
  userManagementFeatureKey
);

const getUserManagement = createSelector(
  getAllUserManagement,
  (userManagement) => userManagement.userManagement
);

export const getAppInitData = createSelector(
  getUserManagement,
  ({ user }) => ({
    user,
    currentOrgName: user?.organisations.results.find(({ id }) => id === user.currentOrgId)?.name || ''
  })
);

export const getAuthUser = createSelector(
  getUserManagement,
  (userManagement) => userManagement.user
);

export const selectAuthUserOrganisations = createSelector(
  getUserManagement,
  (userManagement) => userManagement.user?.organisations.results || []
);

export const selectAuthUserOrgById = (OrgId: string) => createSelector(
  selectAuthUserOrganisations,
  (organisations) => organisations.find((org: IOrganisation) => org.id === OrgId) || null
);

export const getRoles = createSelector(
  getUserManagement,
  (userManagement) => userManagement.roles
);

export const getLoading = createSelector(
  getUserManagement,
  (userManagement) => {
    return userManagement?.loading;
  }
);

export const getUserErrors = createSelector(
  getUserManagement,
  (userManagement) => {
    return userManagement?.error;
  }
);

export const getOrganisationById = (id: string) => createSelector(
  getAllUserManagement,
  (organisations) => organisations.organisations.entities[id]
);
