import { Component, inject, Input } from '@angular/core';
import { ThemeService } from "../../services/theme.service";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styles: [`:host { display: block }`],
})
export class MainHeaderComponent {
  private themeService = inject(ThemeService);
  public isDarkMode$ = this.themeService.isDarkMode()
}
