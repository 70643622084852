import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { UserManagementService } from '../../services/user-management.service';
import { of } from 'rxjs';
import * as fromUserManagement from '../actions/user-management.actions';
import * as fromOrganisationActions from '../actions/organisation.actions';
import { AlertService } from 'src/app/core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ADD_PROFILE_IMAGE_MUTATION,
  DELETE_PROFILE_IMAGE_MUTATION,
  UPDATE_PROFILE_IMAGE_MUTATION
} from '../../graphql/mutations';
import { Router } from '@angular/router';
import * as fromUserManagementActions from "../actions/user-management.actions";
import { EventService } from "../../../../core/services/event.service";

@Injectable()
export class UserManagementEffects {
  constructor(
    private actions$: Actions,
    private userManagementService: UserManagementService,
    public messageService: AlertService,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  loadApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagement.loadApp),
      mergeMap(({ userId, orgId, isNormalUser, isSuperAdmin }) =>
        this.userManagementService.initialiseApp(userId, isNormalUser, isSuperAdmin).pipe(
          map((data) => fromUserManagement.loadAppSuccess({ orgId, data })),
          catchError((error: Error) => {
            console.error('[User Management Effects Error]: ', { error });
            const message = error?.message || '';
            const code = message.includes('403') ? 403 : (message.includes('0') ? 0 : null);
            this.router.navigate(['error'], { queryParams: { code } });
            return of(fromUserManagement.loadAppFailure({ error }));
          })
        )
      )
    )
  );

  fetchAuthUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagement.loadAuthUser),
      mergeMap(({ orgId, userId }) =>
        this.userManagementService.getUser(userId).pipe(
          map((user) =>
            fromUserManagement.loadAuthUserSuccess({ orgId, user })
          ),
          catchError((error) => of(fromUserManagement.loadAuthUserFailure({ error })))
        )
      )
    )
  );

  updateOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.updateOrganisationSuccess),
      map(({ organisation }) => fromUserManagement.updateAuthUserOrganisation({ organisation })),
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagement.updateUserProfile),
      mergeMap(({ userId, user }) =>
        this.userManagementService.updateUserProfile(userId, user).pipe(
          map((user) =>
            fromUserManagement.updateUserProfileSuccess({ user })
          ),
          catchError((error) => of(fromUserManagement.updateUserProfileFailure({ error })))
        )
      )
    )
  );

  resetUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagement.resetUserPassword),
      mergeMap(({ userId }) =>
        this.userManagementService.resetUserPassword(userId).pipe(
          map((userId) => {
            this.messageService.success(
              this.translateService.instant('userManagement.notifications.changePassword.success'),
              this.translateService.instant('actions.dismiss')
            );
            return fromUserManagement.resetUserPasswordSuccess({ userId })
          }),
          catchError((error) => {
            this.messageService.error(
              this.translateService.instant('userManagement.notifications.changePassword.failed')
            );
            return of(fromUserManagement.resetUserPasswordFailure({ error }));
          })
        )
      )
    )
  );

  uploadUserImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagement.uploadUserImage),
      mergeMap(({ id, file, operation }) => {
          const isUpdate = operation.toLocaleLowerCase().includes('update');
          const mutation = isUpdate ? UPDATE_PROFILE_IMAGE_MUTATION : ADD_PROFILE_IMAGE_MUTATION;
          return this.userManagementService.uploadImage(id, file, operation, mutation).pipe(
            map((data) => fromUserManagement.uploadUserImageSuccess({ user: data.user })),
            catchError((error) => of(fromUserManagement.uploadUserImageFailure({ error })))
          )
        }
      ),
    )
  );

  deleteUserImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagement.deleteUserImage),
      mergeMap(({
                  id,
                  operation
                }) => this.userManagementService.deleteImage(id, operation, DELETE_PROFILE_IMAGE_MUTATION).pipe(
        map((data) => fromUserManagement.deleteUserImageSuccess({ user: data.user })),
        catchError((error) => of(fromUserManagement.deleteUserImageFailure({ error })))
      )),
    )
  );

  switchOrganisation$ = createEffect((eventService = inject(EventService)) => this.actions$.pipe(
    ofType(fromUserManagementActions.organisationChanged),
    tap((action) => {
      eventService.switchedOrg(action.organisation)
    })
  ), { dispatch: false });
}
