<div
  [appTooltip]="value.toFixed() + '%' + notificationsBarState"
  tooltipState="info"
  [class.events]="isEvents"
  class="notification p-0" @slideDownPositionAnimation>
  <mat-progress-bar
    [ngClass]="{'big': !embedded, 'events': isEvents}"
    [mode]="mode"
    [value]="value">
  </mat-progress-bar>
</div>
