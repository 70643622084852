import { createAction, props } from '@ngrx/store';
import { PageTrackingData } from 'src/app/core/analytics-next/analytics-next';

export const trackItem = createAction(
  '[AnalyticsNext/API] Create Item',
  props<{ label: string, data: any }>()
);

export const trackPage = createAction(
  '[AnalyticsNext/API] Track Page',
  props<{ data: PageTrackingData }>()
);