import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AnalyticsActions from '../actions/analytics.actions';
import { filter, tap } from 'rxjs/operators';
import { AnalyticsNextService } from '../../core/analytics-next/analytics-next';

@Injectable()
export class AnalyticsEffects {

  constructor(
    private actions$: Actions,
    private analytics: AnalyticsNextService
  ) {}

  trackItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalyticsActions.trackItem),
      tap(({label, data}) => {
         this.analytics.track(label, data);
      })
    )
  }, {dispatch: false});

  trackPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalyticsActions.trackPage),
      filter(({data}) => !!data?.name),
      tap(({data}) => {
       this.analytics.page(data);
      })
    )
  }, {dispatch: false});

}
