import { Directive, ElementRef, HostListener, Injector, Input, OnDestroy, ViewContainerRef, inject, ChangeDetectionStrategy, Component, InjectionToken, TemplateRef } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Helpers } from '../shared/helpers';
import { Direction } from '../model/app.model';

export type TooltipData = string | TemplateRef<void>;
export const TOOLTIP_DATA = new InjectionToken<TooltipData>("Data to display in tooltip");

type TooltipState = 'default' | 'info' | 'warning' | 'error';

@Directive({
    selector: '[appTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnDestroy {
  private element = inject<ElementRef<HTMLElement>>(ElementRef);
  private overlay = inject(Overlay);
  private viewContainer = inject(ViewContainerRef);

  @Input({ required: true }) appTooltip!: TooltipData;
  @Input() tooltipPosition: Direction = 'top';
  @Input() tooltipDisabled: boolean = false;
  @Input() tooltipAutoToggle: boolean = false;
  @Input() tooltipState: TooltipState = 'default';

  private overlayRef: OverlayRef | null = null;

  @HostListener('mouseenter')
  @HostListener('focus')
  showTooltip(): void {
    if (this.tooltipAutoToggle === true) {
      // if the length of the text is more than the text container show the tooltip
      this.checkTextLength();
    }

    if (this.tooltipDisabled === true) {
      return;
    }

    if (this.overlayRef?.hasAttached() === true) {
      return;
    }

    this.attachTooltip();
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  hideTooltip(): void {
    if (this.overlayRef?.hasAttached() === true) {
      this.overlayRef?.detach();
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  private attachTooltip(): void {
    if (this.overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({ positionStrategy });
    }

    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: this.appTooltip,
        },
      ],
    });
    const component = new ComponentPortal(TooltipContainerComponent, this.viewContainer, injector);
    this.overlayRef.attach(component).instance.state = this.tooltipState;
  }

  private checkTextLength(): void {
    const tooltipElement = this.element.nativeElement;
    const tooltipWidth = tooltipElement.getBoundingClientRect().width;
    const textWidth = tooltipElement.querySelector('[data-tooltip-text]')?.getBoundingClientRect().width;
    this.tooltipDisabled = tooltipWidth > textWidth;
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
        Helpers.getOverlayPosition(this.tooltipPosition),
        { // top center
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: 'top'
        },
        { // bottom center
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: 'bottom'
        },
        { // top right
          originX: 'end', 
          originY: 'top',
          overlayX: 'end', 
          overlayY: 'bottom' 
        },
        { // bottom right
          originX: 'end', 
          originY: 'bottom', 
          overlayX: 'end', 
          overlayY: 'top' 
        }
      ]);
  }

}

@Component({
  selector: 'app-tooltip-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgClass],
  template: `
    @if (isString(tooltipData)) {
      <div [ngClass]="{
          'text-blue-800 border-blue-300 bg-blue-50': state === 'info',
          'text-yellow-800 border-yellow-300 bg-yellow-50': state === 'warning',
          'text-red-800 border-red-300 bg-red-50': state === 'error',
          'bg-white': state === 'default'
        }"
        data-cy="tooltip"
        class="z-10 px-2.5 py-1 w-full inline-flex flex-col items-center justify-center subpixel-antialiased outline-none border box-border text-small rounded-md shadow-md">
        <div class="p-1 text-center max-w-[300px]">
          {{ tooltipData }}
        </div>
      </div>
    } @else {
      <ng-template [ngTemplateOutlet]="tooltipData"/>
    }
  `,
})
export class TooltipContainerComponent {
  @Input() state: TooltipState = 'default';

  protected tooltipData = inject<TooltipData>(TOOLTIP_DATA);

  isString(value: TooltipData): value is string {
    return typeof value === "string" && value.length > 0;
  }

  isTemplate(value: TooltipData): value is TemplateRef<void> {
    return this.tooltipData instanceof TemplateRef;
  }
}