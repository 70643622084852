import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DropdownScrollDirective } from 'src/app/core/directives/dropdown-scroll.directive';
import { PopperDirective } from 'src/app/core/directives/popper.directive';

@Component({
  selector: 'app-nav-list-group',
  templateUrl: './nav-list-group.component.html',
  standalone: true,
  imports: [NgClass, PopperDirective, DropdownScrollDirective]
})
export class NavListGroupComponent {
  @Input() isScenarioNavGroup: boolean = false;
  @Input() listCount: number = 0;
  @Input() closeDelay: number = 350;
  constructor() { }
}
