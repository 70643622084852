import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  constructor() { }

  private openDatabase(dbName: string, store: string, dbVersion?: number): Promise<IDBDatabase> {
    // Return a promise that resolves when the database is ready
    return new Promise((resolve, reject) => {
      const request = typeof dbVersion === 'number' ? indexedDB.open(dbName, dbVersion) : indexedDB.open(dbName);
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;

        // Create an object store if it doesn't exist
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store);
        }
      };
      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };
      request.onerror = (event) => {
        console.error('Error opening IndexedDB:', event);
        reject(event);
      };
    });
  }

  async idbAddOne(dbName: string, storeName: string, key: string, value: any, versionUpdate?: number | null): Promise<any> {
    const db = await this.openDatabase(dbName, storeName, versionUpdate);
    // check if the store exists
    if (db.objectStoreNames.contains(storeName)) {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const data = store.add(value, key);
      return new Promise((resolve, reject) => {
        data.onsuccess = (event) => {
          resolve((event.target as IDBRequest).result);
        };
        data.onerror = (event) => {
          console.error('Error Adding to IndexedDB:', event);
          reject(event);
        };
      });
    }
    // if the store does not exist, upgrade the database and try again
    const newVersion = db.version + 1;
    db.close();
    return this.idbAddOne(dbName, storeName, key, value, newVersion);
  }

  async idbDeleteOne(dbName: string, storeName: string, key: string, versionUpdate?: number | null): Promise<any> {
    const db = await this.openDatabase(dbName, storeName, versionUpdate);
    // check if the store exists
    if (db.objectStoreNames.contains(storeName)) {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const data = store.delete(key);
      return new Promise((resolve, reject) => {
        data.onsuccess = (event) => {
          resolve((event.target as IDBRequest).result);
        };
        data.onerror = (event) => {
          console.error('Error Deleting from IndexedDB:', event);
          reject(event);
        };
      });
    }
  }

  async idbGetOne(dbName: string, storeName: string, key: string, versionUpdate?: number | null): Promise<any> {
    const db = await this.openDatabase(dbName, storeName, versionUpdate);
    // check if the store exists
    if (db.objectStoreNames.contains(storeName)) {
      const transaction = db.transaction([storeName], 'readonly');
      const store = transaction.objectStore(storeName);
      const data = store.get(key);
  
      return new Promise((resolve, reject) => {
        data.onsuccess = (event) => {
          resolve((event.target as IDBRequest).result);
        };
        data.onerror = (event) => {
          console.error('Error getting from IndexedDB:', event);
          reject(event);
        };
      });
    }
    // if the store does not exist, upgrade the database and try again
    const newVersion = db.version + 1;
    db.close();
    return this.idbGetOne(dbName, storeName, key, newVersion);
  }

  async idbUpsertOne(dbName: string, storeName: string, key: string, value: any, versionUpdate?: number | null): Promise<void> {
    const db = await this.openDatabase(dbName, storeName, versionUpdate);
    // check if the store exists
    if (db.objectStoreNames.contains(storeName)) {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const data = store.put(value, key);
      return new Promise((resolve, reject) => {
        data.onsuccess = (event) => {
          resolve((event.target as IDBRequest).result);
        };
        data.onerror = (event) => {
          console.error('Error Updating IndexedDB:', event);
          reject(event);
        };
      });
    }
    // if the store does not exist, upgrade the database and try again
    const newVersion = db.version + 1;
    db.close();
    return this.idbUpsertOne(dbName, storeName, key, value, newVersion);
  }

  async idbGetAll(dbName: string, storeName: string, versionUpdate?: number | null): Promise<any> {
    const db = await this.openDatabase(dbName, storeName, versionUpdate);
    // check if the store exists
    if (db.objectStoreNames.contains(storeName)) {
      const transaction = db.transaction([storeName], 'readonly');
      const storeObj = transaction.objectStore(storeName);
      const data = storeObj.getAll();

      return new Promise((resolve, reject) => {
        data.onsuccess = (event) => {
          resolve((event.target as IDBRequest).result);
        };
        data.onerror = (event) => {
          console.error('Error getting all from IndexedDB:', event);
          reject(event);
        };
      });
    }
    // if the store does not exist, upgrade the database and try again
    const newVersion = db.version + 1;
    db.close();
    return this.idbGetAll(dbName, storeName, newVersion);
  }

}
