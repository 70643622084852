import { ScenarioSegment } from 'src/app/views/scenario/model/scenario-segments.model';
import { EventsSegments } from '../../views/events/model/events.model';
import { MatSelectChange } from '@angular/material/select';
import { DEFAULT_DATE_RANGES } from "../app.constants";
import moment from 'moment';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Direction } from '../model/app.model';

export class Helpers {
  static getCustomSegments(formValueSegments: string[]): string[] {
    // Remove 'GLOBAL' from formGroup values which is set in the SegmentInputComponent
    return formValueSegments.filter(segment => segment !== EventsSegments.GLOBAL);
  }

  static addMissingSegments(segments: []): string[] {
    const globalValue = [EventsSegments.GLOBAL.toString()];
    return segments.length ? segments : globalValue;
  }

  static hasOrganicSegments(segments: ScenarioSegment[], formValueSegmentIds: string[]): boolean {
    const segmentsMapList = new Map(segments.map(segment => [segment.id, segment]));
    const formValueSegments = formValueSegmentIds.map(id => segmentsMapList.get(id)?.name.toLowerCase());
    return formValueSegments.some(segment => segment?.includes(EventsSegments.ORGANIC.toString()));
  }

  static resetGlobalSegment($change: MatSelectChange, callback: () => void) {
    const isGlobalButtonActive = () => $change.source?.options.first.active;
    const isGlobalChecked = () => $change.value.map(v => v.toLowerCase()).includes(EventsSegments.GLOBAL.toLowerCase());
    if (isGlobalChecked() && !isGlobalButtonActive()) {
      $change.source?.options.first.deselect();
    }

    if (isGlobalChecked() && isGlobalButtonActive()) {
      callback();
    }
  }

  static camelToSnakeCase(input: string) {
    return input.replace(/[A-Z]/g, letter => `_${ letter.toLowerCase() }`);
  }

  static toFixedNumber(percentage: number) {
    if (percentage === 0) return 0;
    if (!percentage) return null;
    return Math.round(percentage * 1e2) / 1e2;
  }

  static roundToDecimalPlaces(value: number, decimalPlaces: number) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(value * factor) / factor;
  }

  static removeKey(key: string, object: any) {
    const { [key]: _, ...rest } = object;
    return rest;
  }

  static removeKeys(keys: string[], filters: any) {
    return keys.reduce((acc, key) => Helpers.removeKey(key, acc), filters);
  }

  static removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != '')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  static hasDefaultDateRange(object: any) {
    if (!object['Date Range']) return true;
    if (object['Granularity'] === 'Day' && object['Date Range'] === DEFAULT_DATE_RANGES.Daily["Date Range"]) {
      return true;
    } else if (object['Granularity'] !== 'Day' && object['Date Range'] === DEFAULT_DATE_RANGES.Other["Date Range"]) {
      return true;
    }
    return false;
  }

  static getRetentionCurveFilter(endDate: string | Date) {
    const start = moment(endDate).add(1, 'day').format('YYYY/MM/DD');
    const end = moment(endDate).add(1, 'month').format('YYYY/MM/DD');
    return {
      'Retention Date Range': `${ start } to ${ end }`
    }
  };

  static pluckKeys(keys: string[], object: any) {
    return keys.reduce((acc, key) => {
      if (key === 'Date Range') {
        acc[key] = object[key];
        // We don't want to persist date ranges if they are the default
        if (Helpers.hasDefaultDateRange(object)) {
          delete acc[key];
        }
      } else if (object[key] !== undefined && object[key] !== "") {
        acc[key] = object[key];
      } else if (object[key] === "") {
        // Unset any blank values as we don't want to persist an empty date range
        delete acc[key];
      }
      return acc;
    }, {});
  }

  static getOverlayPosition(postion: Direction = 'top'): ConnectedPosition {
    switch (postion) {
      case 'right':
        return { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' };
      case 'bottom':
        return { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', panelClass: 'bottom' };
      case 'left':
        return { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' };
      default: // defaults to top
        return { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', panelClass: 'top' };
    }
  }
}
