import { Injectable } from '@angular/core';
import Pusher, { Authorizer, AuthorizerCallback, Channel } from 'pusher-js';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/reducers';
import * as fromScenario from '../../store/actions/scenario.actions';
import { ConfigAwareService } from '../config/models/config';
import { HttpClient } from '@angular/common/http';
import { IScenarioJobDto } from 'src/app/views/scenario/model/scenario-job-dto.model';
import { IScenario, IScenarioUserEventPayload } from "../../views/scenario/model/scenario.model";
import { AuthService, User } from "@auth0/auth0-angular";
import * as UserManagementActions from "../../views/user-management/redux/actions";
import { IOrganisation } from "../../views/user-management/model/user-management.model";
import { getOrganisationById } from "../../views/user-management/redux/selectors";
import { take } from "rxjs/operators";
import * as fromUserManagementActions from "../../views/user-management/redux/actions/user-management.actions";
import { ConfirmationService } from "./confirmation.service";
import { TranslateService } from "@ngx-translate/core";

export enum EventType {
  scenarioProcessed = 'scenarioProcessed',
  scenarioSnapshotDataDeleted = 'scenarioSnapshotDataDeleted',
  segmentUpdated = 'segmentUpdated',
  scenarioUpdated = 'scenarioUpdated',
  productUpdated = 'productUpdated',
  scenarioOverrideUpdated = 'scenarioOverrideUpdated',
  scenarioJobUpdated = 'scenarioJobUpdated',
  scenarioUserEventLogged = 'scenarioUserEventLogged',
  organisationChanged = 'client-organisationChanged',
}

export interface IPusherPayload {
  orgId: string;
  productId: number;
  scenarioId: number;
}

export interface IScenarioFileOverrideEvent extends IPusherPayload {
  fileId: number;
}

@Injectable({
  providedIn: 'root'
})
export class EventService extends ConfigAwareService {

  private pusherClient: Pusher;
  private accountId: string;
  private productEvents = new Subject<number>();
  private scenarioEvents = new Subject<IPusherPayload>();
  private scenarioFileOverrideEvent = new Subject<IScenarioFileOverrideEvent>();
  private segmentEvents = new Subject<IPusherPayload>();
  private scenarioJobUpdated: Subject<IScenarioJobDto> = new Subject<IScenarioJobDto>();

  constructor(
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private confirmationService: ConfirmationService,
    private auth0Service: AuthService,
    private translateService: TranslateService,
  ) {
    super();
    this.pusherClient = new Pusher(this.environment.pusher.key, {
      authEndpoint: this.environment.resourceServer.runwayUrl + 'pusher/auth',
      cluster: 'eu',
      forceTLS: true,
      authorizer: (channel, options: any): Authorizer => {
        const vm = this;
        return {
          authorize(socketId: string, callback: AuthorizerCallback) {
            vm.httpClient.post<any>(options.authEndpoint, null, {
              params: {
                channel_name: channel.name,
                socket_id: socketId,
              },
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).subscribe(response => {
              callback(null, {
                auth: response.auth,
                channel_data: response.channel_data
              });
            }, error => {
              callback(error, null);
            });
          }
        };
      },
    });
  }

  channel: Channel;
  privateChannel: Channel;

  start(orgId: string, identity: User): void {
    if (this.accountId) {
      this.channel.unsubscribe()
    }
    this.accountId = orgId;
    this.channel = this.pusherClient.subscribe(`private-${ this.accountId }`);
    this.privateChannel = this.pusherClient.subscribe(`private-${ identity.sub.replace('|', '') }`);
    this.listenForScenarioUpdatedEvents();
    this.listenForProductUpdatedEvents();
    this.listenForUserEvents(orgId);
  }

  private listenForScenarioUpdatedEvents() {
    this.channel.bind(
      EventType.scenarioProcessed,
      (data: IPusherPayload & { name: string }) => {
        const { scenarioId, productId, orgId, name } = data;
        this.handlePayload(orgId, () => {
          this.store.dispatch(fromScenario.scenarioProcessed({ scenarioId, productId, name }));
        });
      }
    );

    this.channel.bind(
      EventType.scenarioSnapshotDataDeleted,
      (data: IPusherPayload) => {
        const { scenarioId, orgId } = data;
        this.handlePayload(orgId, () => {
          this.store.dispatch(fromScenario.scenarioSnapshotDataDeleted({ scenarioId }));
        });
      }
    );

    this.channel.bind(
      EventType.scenarioUpdated,
      (data: IPusherPayload & { scenario: IScenario }) => {
        const { scenario } = data;
        this.scenarioEvents.next(data);
        this.store.dispatch(fromScenario.updateScenarioSuccess({ scenario: { id: scenario.id, changes: scenario } }))
      }
    );

    this.channel.bind(
      EventType.segmentUpdated,
      (data: IPusherPayload) => {
        const { orgId } = data;
        this.handlePayload(orgId, () => {
          this.segmentEvents.next(data);
        });
      }
    );

    this.channel.bind(
      EventType.scenarioOverrideUpdated,
      (data: IScenarioFileOverrideEvent) => {
        const { orgId } = data;
        this.handlePayload(orgId, () => {
          this.scenarioFileOverrideEvent.next(data);
        });
      }
    );

    this.channel.bind(
      EventType.scenarioJobUpdated,
      (data: IPusherPayload & { scenarioJob: IScenarioJobDto }) => {
        const { ...scenarioJob } = data;
        this.scenarioJobUpdated.next(data);
        this.store.dispatch(fromScenario.setScenarioJob({ scenarioJob }));
      }
    );

    this.channel.bind(
      EventType.scenarioUserEventLogged,
      (data: IScenarioUserEventPayload) => {
        this.store.dispatch(fromScenario.scenarioUserEventLogged({ scenarioUserEvent: data }));
      }
    );
  }

  private listenForProductUpdatedEvents() {
    this.channel.bind(
      EventType.productUpdated,
      (data: Omit<IPusherPayload, 'scenarioId'>) => {
        const { orgId, productId } = data;
        this.handlePayload(orgId, () => {
          this.productEvents.next(productId);
        });
      }
    );
  }

  public switchedOrg(organisation: IOrganisation) {
    this.privateChannel.trigger(EventType.organisationChanged, { organisation });
  }

  private listenForUserEvents(orgId: string) {
    this.privateChannel.bind(
      EventType.organisationChanged,
      (data: { organisation: IOrganisation }) => {
        if (data.organisation.id !== orgId) {
          this.confirmationService.confirm(
            () => {
              /**
               *  If they want to switch we just reload the page, as they are now logged in as the new org
               */
              window.location.reload()
            },
            () => {
              /**
               * Re-login as the existing organisation
               */
              this.auth0Service.getAccessTokenSilently({
                cacheMode: 'off',
                authorizationParams: { organization: orgId },
              }).subscribe(async (token) => {
                const organisation = await lastValueFrom(this.store.select(getOrganisationById(orgId)).pipe(take(1)));
                this.store.dispatch(fromUserManagementActions.organisationChanged({
                  organisation
                }));
              });
            },
            this.translateService.instant('userManagement.confirmation.switchOrg.message', { name: data.organisation.name }),
            this.translateService.instant('userManagement.confirmation.switchOrg.title'),
            this.translateService.instant('userManagement.confirmation.switchOrg.confirm')
          )
        }
      }
    )
  }

  private handlePayload(orgId: string, callback: () => void) {
    if (this.accountId === orgId) {
      callback();
    }
  }

  getProductEvents(): Observable<number> {
    return this.productEvents.asObservable();
  }

  getScenarioEvents(): Observable<IPusherPayload> {
    return this.scenarioEvents.asObservable();
  }

  getSegmentEvents(): Observable<IPusherPayload> {
    return this.segmentEvents.asObservable();
  }

  getScenarioFileOverrideEvents(): Observable<IScenarioFileOverrideEvent> {
    return this.scenarioFileOverrideEvent.asObservable();
  }

  getScenarioJobUpdatedEvents(): Observable<IScenarioJobDto> {
    return this.scenarioJobUpdated.asObservable();
  }

  get productProcessed() {
    return this.productEvents;
  }

  get segmentPusherEvents() {
    return this.segmentEvents;
  }

}
